<template>
  <a href="#" class="scrolldown-container">
    <div class="scrolldown"></div>
    <!-- <span class="scrolldown-txt">Scroll down to Explore</span> -->
  </a>
</template>

<script>

import { computed, onMounted } from 'vue';

export default {
  setup() {
    onMounted(()=>{

      $(".scrolldown-container").on("click", function(e) {
        e.preventDefault();
        var offset = $(".content-intro").offset().top;
        window.scrollTo({top: offset-80, behavior: 'smooth'});
      });

    });
    return {}
  }
  
}
</script>