<template>
  <section class="site-error">

    <div class="content content-main first-container container-fluid">
      <div class="inner-container">
        <div class="lottie-container">
          <lottie
            :options="defaultOptions"
            :height="'auto'"
            :width="'100%'"
            v-on:animCreated="handleAnimation"
          />
        </div>
        <span class="project-name">Nothing to see here</span>
      </div>
    </div>

  </section>
</template>

<script>
import { ref, onMounted, onBeforeUnmount, reactive, computed, onBeforeMount } from 'vue';
import Lottie from "vue-lottie";
import * as animation404Data from '@/assets/404_anim.json';
import { useRoute, useRouter } from 'vue-router';
import Func from '@/helpers/Function';
import Data from '@/helpers/Data';
import Footer from '@/components/Footer.vue';
import Scrolldown from '@/components/Scrolldown.vue';
import { useStore } from 'vuex';

export default {
  components: { 
    Footer: Footer,
    Scrolldown: Scrolldown
  },
  setup(props, {emit}) {
    const defaultOptions = {animationData: animation404Data.default, renderer: 'svg', rendererSettings: { progressiveLoad:false, preserveAspectRatio: 'xMaxYMax slice' },};
    const animationSpeed = 1;
		const router = useRouter();
    const store = useStore();
    const userMode = computed(() => store.state.account.mode);
    
    onMounted(() => {
      Func.resetPage();
      Func.setupScrolltop();

      if(userMode.value == 'dark') {
        $(".site-error").addClass('darkmode');
      } else {
        $(".site-error").removeClass('darkmode');
      }
    });

    function handleAnimation (anim) {
    }

    function stop (anim) {
      anim.stop();
    }

    return {
      Lottie,
      defaultOptions,
      animationSpeed,
      handleAnimation,
      stop,
      Footer,
    }
  }
}
</script>

<style scoped>
</style>
